import type { OpeningTimes } from '~/types'

/**
 * Formats date as: yyyy-MM-dd
 */
export const formatDate = (
  date: string | Date,
  format?: 'input' | 'display'
) => {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  if (format == 'input') {
    return [year, month, day].join('-')
  } else {
    return [day, month, year].join('.')
  }
}

/**
 * Works out the difference between two days and returns it in days
 */

export const daysBetween = (
  startDate: string | Date,
  endDate: string | Date
) => {
  const date1 = new Date(startDate)
  const date2 = new Date(endDate)

  // Calculating the time difference
  const diffTime = date2.getTime() - date1.getTime()

  // Calculating the no. of days between
  const diffDays = Math.round(diffTime / (1000 * 3600 * 24))

  // To display the final no. of days (result)
  return diffDays + ' days'
}

/**
 * Works out the days open and close times of a particular branch
 */

const daysOfWeek = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
  'bankHolidays',
]

export const getBranchTimes = (t: OpeningTimes, d: number) => {
  return Object.entries(t).find(([key]) => key === daysOfWeek[d])?.[1]
}
